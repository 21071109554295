<template>

    <div class="pu_wrapper pu_web">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board" style="background-color: #f5f5f5;">

            <div class="pu_row">
                <div class="pu_contents">

                    <div class="pu_row">

                        <div class="pu_col_lg_8">
                            <div class="pu_contents pu_index_panel">

                                <div class="pu_index_gold_title pu_col_lg_2">
                                    NOTICE :
                                </div>
                                <div class="pu_index_gold_contents pu_col_lg_10">
                                    <a href="javascript:;" v-on:click="$PageMove('./board_gold_view?no=' + view_1.GOLD_NO)">
                                        {{ view_1.GOLD_TITLE }}
                                    </a>
                                </div>

                            </div>
                        </div>

                        <div class="pu_col_lg_4">
                            <div class="pu_contents pu_index_panel">
                                <a class="pu_index_phone" href="tel:031-263-8471">
                                    <i class="fas fa-mobile-alt"></i> 031-263-8471
                                </a>
                            </div>
                        </div>

                    </div>

                    <div class="pu_row">

                        <div class="pu_col_lg_8">
                            <div class="pu_contents pu_index_panel">

                                <table class="pu_index_table_view">
                                    <colgroup>
                                        <col width="275px">
                                        <col width="50%">
                                        <col width="50%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">오늘의 시세</span>
                                                <span class="pu_text_small">({{ view_1.GOLD_EDIT_DATE }})</span>
                                            </th>
                                            <th>
                                                사실 때 We Sell (VAT 포함)
                                            </th>
                                            <th>
                                                파실 때 We Buy
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span class="pu_text_color_gold pu_margin_right_10">
                                                    24K덩어리
                                                </span>
                                                <span class="pu_text_small">
                                                    Gold 24k 3.75g
                                                </span>
                                            </th>
                                            <td>
                                                <span></span>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.BUY_GOLD_24_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.BUY_GOLD_24_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.BUY_GOLD_24_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.BUY_GOLD_24_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_1"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span></span>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.SELL_GOLD_24_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.SELL_GOLD_24_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.SELL_GOLD_24_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.SELL_GOLD_24_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_2"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_text_color_gold pu_margin_right_10">
                                                    18K금시세
                                                </span>
                                                <span class="pu_text_small">
                                                    Gold 18k 3.75g
                                                </span>
                                            </th>
                                            <td>
                                                <div class="pu_sign_price_wrapper">
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span style="font-size:18px;">제품시세적용</span>
                                                </div>
                                            </td>
                                            <!--
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.BUY_GOLD_18_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.BUY_GOLD_18_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.BUY_GOLD_18_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.BUY_GOLD_18_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_3"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                            -->
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.SELL_GOLD_18_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.SELL_GOLD_18_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.SELL_GOLD_18_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.SELL_GOLD_18_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_4"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_text_color_gold pu_margin_right_10">
                                                    14K금시세
                                                </span>
                                                <span class="pu_text_small">
                                                    Gold 14k 3.75g
                                                </span>
                                            </th>
                                            <td>
                                                <div class="pu_sign_price_wrapper">
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span style="font-size:18px;">제품시세적용</span>
                                                </div>
                                            </td>
                                            <!--
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.BUY_GOLD_14_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.BUY_GOLD_14_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.BUY_GOLD_14_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.BUY_GOLD_14_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_5"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                            -->
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.SELL_GOLD_14_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.SELL_GOLD_14_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.SELL_GOLD_14_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.SELL_GOLD_14_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_6"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">
                                                    백금시세
                                                </span>
                                                <span class="pu_text_small">
                                                    Platinum 3.75g
                                                </span>
                                            </th>
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.BUY_GOLD_WHITE_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.BUY_GOLD_WHITE_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.BUY_GOLD_WHITE_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.BUY_GOLD_WHITE_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_7"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.SELL_GOLD_WHITE_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.SELL_GOLD_WHITE_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.SELL_GOLD_WHITE_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ $ConMoney(view_1.SELL_GOLD_WHITE_VAR) }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_8"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">
                                                    은시세
                                                </span>
                                                <span class="pu_text_small">
                                                    Silver 3.75g
                                                </span>
                                            </th>
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.BUY_GOLD_SILVER_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.BUY_GOLD_SILVER_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.BUY_GOLD_SILVER_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ view_1.BUY_GOLD_SILVER_VAR }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_9"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_sign_price_wrapper">

                                                    <span class="pu_sign_price_up" v-if="view_1.SELL_GOLD_SILVER_GUBUN == 'U'">
                                                        <i class="fas fa-caret-up"></i>
                                                    </span>
                                                    <span class="pu_sign_price_nn" v-if="view_1.SELL_GOLD_SILVER_GUBUN == 'N'">
                                                        <i class="fas fa-minus"></i>
                                                    </span>
                                                    <span class="pu_sign_price_dw" v-if="view_1.SELL_GOLD_SILVER_GUBUN == 'D'">
                                                        <i class="fas fa-sort-down"></i>
                                                    </span>

                                                    <span class="pu_sign_price">
                                                        {{ view_1.SELL_GOLD_SILVER_VAR }}
                                                    </span>
                                                </div>
                                                <div class="pu_price_wrapper">
                                                    <span id="price_gold_10"></span>
                                                    <span class="pu_text_small"> 원</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div class="pu_col_lg_4">
                            <div class="pu_contents pu_index_panel">

                                <div class="dev_index_item_main pu_index_item_main"
                                     style="background-image: url('/common/img/prod_buy_item_7.jpg'); cursor:pointer;"
                                     v-on:click="$PageMove('./buy_prod' + '')">
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="pu_row">

                        <div class="pu_col_lg_8">
                            <div class="pu_contents pu_index_panel">

                                <table class="pu_index_table_view">
                                    <colgroup>
                                        <col width="275px">
                                        <col width="33.3%">
                                        <col width="33.3%">
                                        <col width="33.3%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                다이아몬드 매입시세
                                            </th>
                                            <th>
                                                우신
                                            </th>
                                            <th>
                                                현대
                                            </th>
                                            <th>
                                                GIA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">0.21ct</span>
                                                <span class="pu_text_small">(G, VVS1)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_21_WS_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_21_HD_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_call">
                                                    전화문의
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">0.31ct</span>
                                                <span class="pu_text_small">(G, VVS1)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_31_WS_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_31_HD_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_call">
                                                    전화문의
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">0.51ct</span>
                                                <span class="pu_text_small">(G, VVS1)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_51_WS_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_51_HD_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_call">
                                                    전화문의
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">0.71ct</span>
                                                <span class="pu_text_small">(G, VVS1)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_71_WS_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.DIA_71_HD_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="pu_price_call">
                                                    전화문의
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div class="pu_col_lg_4">
                            <div class="pu_contents pu_index_panel">

                                <table class="pu_index_table_view">
                                    <colgroup>
                                        <col width="200px">
                                        <col width="100%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                은제품 매입시세
                                            </th>
                                            <th>
                                                1벌 기준
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">은수저 99%</span>
                                                <span class="pu_text_small">(112.50g)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.SV_SP_99_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">은수저 90%</span>
                                                <span class="pu_text_small">(98.75g)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.SV_SP_90_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">은수저 80%</span>
                                                <span class="pu_text_small">(98.75g)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.SV_SP_80_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span class="pu_margin_right_10">은수저 70%</span>
                                                <span class="pu_text_small">(98.75g)</span>
                                            </th>
                                            <td>
                                                <div class="pu_price_wrapper">
                                                    {{ $ConMoney(view_1.SV_SP_70_NOW) }}
                                                    <span class="pu_text_small">원</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>

                    <div class="pu_row">

                        <div class="pu_col_lg_3">
                            <div class="pu_contents pu_index_panel">

                                <div class="pu_index_chart_wrapper">
                                    <div class="pu_index_chart_title">
                                        <img src="/common/img/item/logo_kitco.png" style="width: 23px;" />
                                        KITCO 실시간 국제 금시세
                                    </div>
                                    <div class="pu_index_chart_body">
                                        <!--src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#%7B%22symbol%22%3A%22TVC%3AGOLD%22%2C%22width%22%3A176%2C%22height%22%3A173%2C%22dateRange%22%3A%223M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22%23a67b52%22%2C%22underLineColor%22%3A%22%23f0e2d4%22%2C%22isTransparent%22%3Atrue%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.e-goldtech.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.e-goldtech.com%2Fgoldtech_chart.php%22%7D'-->
                                        <iframe id="tradingview_0299a"
                                                src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#{"symbol": "TVC:GOLD","width": 176,"height": 173,"locale": "kr","dateRange": "3M","colorTheme": "light","isTransparent": false,"autosize": false,"largeChartUrl": "","trendLineColor": "rgba(255, 0, 0, 1)","underLineColor": "rgba(244, 204, 204, 1)","underLineBottomColor": "rgba(255, 255, 255, 0)"}'
                                                frameborder="0" allowtransparency="true" scrolling="no"
                                                allowfullscreen=""></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="pu_col_lg_3">
                            <div class="pu_contents pu_index_panel">

                                <div class="pu_index_chart_wrapper">
                                    <div class="pu_index_chart_title">
                                        <img src="/common/img/item/logo_kitco.png" style="width: 23px;" />
                                        KITCO 실시간 국제 은시세
                                    </div>
                                    <div class="pu_index_chart_body">
                                        <!--src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#%7B%22symbol%22%3A%22TVC%3ASILVER%22%2C%22width%22%3A176%2C%22height%22%3A173%2C%22dateRange%22%3A%223M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22%23777777%22%2C%22underLineColor%22%3A%22%23f2f2f2%22%2C%22isTransparent%22%3Atrue%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.e-silvertech.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.e-silvertech.com%2Fsilver_chart.php%22%7D'-->
                                        <iframe id="tradingview_0299a"
                                                src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#{"symbol": "TVC:SILVER","width": 176,"height": 173,"locale": "kr","dateRange": "3M","colorTheme": "light","isTransparent": false,"autosize": false,"largeChartUrl": "","trendLineColor": "rgba(255, 0, 0, 1)","underLineColor": "rgba(244, 204, 204, 1)","underLineBottomColor": "rgba(255, 255, 255, 0)"}'
                                                frameborder="0" allowtransparency="true" scrolling="no"
                                                allowfullscreen=""></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="pu_col_lg_3">
                            <div class="pu_contents pu_index_panel">

                                <div class="pu_index_chart_wrapper">
                                    <div class="pu_index_chart_title">
                                        <i class="fas fa-dollar-sign"></i> 환 율 정 보
                                    </div>
                                    <div class="pu_index_chart_body">
                                        <!--src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#%7B%22symbol%22%3A%22FX_IDC%3AUSDKRW%22%2C%22width%22%3A176%2C%22height%22%3A173%2C%22dateRange%22%3A%223M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22%23a67b52%22%2C%22underLineColor%22%3A%22%23f0e2d4%22%2C%22isTransparent%22%3Atrue%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.e-goldtech.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.e-goldtech.com%2Fgoldtech_chart.php%22%7D'-->
                                        <iframe id="tradingview_0299a"
                                                src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#{"symbol": "FX_IDC:USDKRW","width": 176,"height": 173,"locale": "kr","dateRange": "3M","colorTheme": "light","isTransparent": false,"autosize": false,"largeChartUrl": "","trendLineColor": "rgba(255, 0, 0, 1)","underLineColor": "rgba(244, 204, 204, 1)","underLineBottomColor": "rgba(255, 255, 255, 0)"}'
                                                frameborder="0" allowtransparency="true" scrolling="no"
                                                allowfullscreen=""></iframe>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="pu_col_lg_3">
                            <div class="pu_contents pu_index_panel">

                                <div class="pu_index_chart_wrapper">
                                    <div class="pu_index_chart_title">
                                        실시간 나스닥 지수
                                    </div>
                                    <div class="pu_index_chart_body">
                                        <!--src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#%7B%22symbol%22%3A%22KRX%3AKOSPI%22%2C%22width%22%3A176%2C%22height%22%3A173%2C%22dateRange%22%3A%223M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22%23a67b52%22%2C%22underLineColor%22%3A%22%23f0e2d4%22%2C%22isTransparent%22%3Atrue%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.e-goldtech.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.e-goldtech.com%2Fgoldtech_chart.php%22%7D'-->
                                        <iframe id="tradingview_0299a"
                                                src='https://www.tradingview-widget.com/embed-widget/mini-symbol-overview/?locale=kr#{"symbol": "NASDAQ:IXIC","width": 176,"height": 173,"locale": "kr","dateRange": "3M","colorTheme": "light","isTransparent": false,"autosize": false,"largeChartUrl": "","trendLineColor": "rgba(255, 0, 0, 1)","underLineColor": "rgba(244, 204, 204, 1)","underLineBottomColor": "rgba(255, 255, 255, 0)"}'
                                                frameborder="0" allowtransparency="true" scrolling="no"
                                                allowfullscreen=""></iframe>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="pu_row owl-carousel">

                        <div class="" v-for="(row, idx) in list_4">
                            <div class="pu_contents pu_index_panel">

                                <div class="pu_index_item_sub_1"
                                     v-bind:style="{ backgroundImage: 'url(' + row.URL_IMG_ITEM_THUM + ')', cursor: 'pointer' }"
                                     v-on:click="$PageMove('./prod_list?page_gubun=' + row.GUBUN)">
                                </div>

                                <div class="pu_index_item_sub_2">
                                    <div>{{ row.TITLE }}</div>
                                    <span v-if="row.PRICE > 0">{{ $ConMoney(row.PRICE) }} 원</span>
                                    <span v-if="row.PRICE < 1">전화문의</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_col_lg_8">

                            <div class="pu_col_lg_6">
                                <div class="pu_contents pu_index_panel">

                                    <div class="pu_index_board_wrapper">
                                        <div class="pu_index_board_title">
                                            <span>금관련소식</span>
                                            <a class="pu_index_board_more" href="javascript:;" v-on:click="$PageMove('./board_gold' + '')">
                                                더보기
                                                <i class="far fa-plus-square"></i>
                                            </a>
                                        </div>
                                        <div class="pu_index_board_body">
                                            <ul>
                                                <li v-for="(row, idx) in list_1">
                                                    <a class="pu_index_board_a" href="javascript:;" v-on:click="$PageMove('./board_gold_view?no=' + row.NO)">
                                                        - {{ row.TITLE }}&emsp;( {{ row.REGI_DATE }} )
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="pu_col_lg_6">
                                <div class="pu_contents pu_index_panel">

                                    <div class="pu_index_board_wrapper">
                                        <div class="pu_index_board_title">
                                            <span>고객상담센터</span>
                                        </div>
                                        <div class="pu_index_board_body" style="text-align:center;">

                                            <a class="pu_index_intro_time_1">
                                                월-금요일 : 오전 11:00 ~ 오후 20:00
                                            </a>

                                            <a class="pu_index_intro_time_2">
                                                토-일요일 : 오전 10:00 ~ 오후 17:00
                                            </a>

                                            <a class="pu_index_intro_sub">
                                                ** 공휴일은 휴무입니다 **
                                            </a>

                                            <a class="pu_index_intro_phone" href="tel:031-263-8471">
                                                <i class="fas fa-mobile-alt"></i> 031-263-8471
                                            </a>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="pu_col_lg_6">
                                <div class="pu_contents pu_index_panel">

                                    <div class="pu_index_board_wrapper">
                                        <div class="pu_index_board_title">
                                            <span>공지사항</span>
                                            <a class="pu_index_board_more" href="javascript:;" v-on:click="$PageMove('./board_notice' + '')">
                                                더보기
                                                <i class="far fa-plus-square"></i>
                                            </a>
                                        </div>
                                        <div class="pu_index_board_body">
                                            <ul>
                                                <li v-for="(row, idx) in list_2">
                                                    <a class="pu_index_board_a" href="javascript:;" v-on:click="$PageMove('./board_notice_view?no=' + row.NO)">
                                                        - {{ row.TITLE }}&emsp;( {{ row.REGI_DATE }} )
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="pu_col_lg_6">
                                <div class="pu_contents pu_index_panel">

                                    <div class="pu_index_board_wrapper">
                                        <div class="pu_index_board_title">
                                            <span>문의게시판</span>
                                            <a class="pu_index_board_more" href="javascript:;" v-on:click="$PageMove('./board_qna' + '')">
                                                더보기
                                                <i class="far fa-plus-square"></i>
                                            </a>
                                        </div>
                                        <div class="pu_index_board_body">
                                            <ul>
                                                <li v-for="(row, idx) in list_3">
                                                    <a class="pu_index_board_a" href="javascript:;" v-on:click="$PageMove('./board_qna_view?no=' + row.NO)">
                                                        - {{ row.TITLE }}&emsp;( {{ row.REGI_DATE }} )
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="pu_col_lg_4">

                            <div class="pu_col_lg_12">
                                <div class="pu_contents pu_index_panel pu_index_map_address">

                                    <div class="pu_index_board_wrapper">
                                        <div class="pu_index_board_title">
                                            <span>찾아오시는길</span>
                                        </div>
                                        <div class="pu_index_board_body">
                                            <div id="map">
                                                <a href="https://map.kakao.com/?itemId=18838315&urlX=517883.0&urlY=1059855.0&map_type=TYPE_MAP&from=roughmap"
                                                   target="_blank"
                                                   style="width:100%; height:100%; display:block; overflow:hidden; cursor:pointer;">
                                                    <img src="/common/img/item/location_daum.jpg" alt="찾아오시는길" 
                                                         style="width:100%;"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "HOME"

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                // 메인 이미지
                , list_main_item: []

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // 메인 이미지 loop
                this.loopMainItem();

                this.getViewData();

                //---------------- 다음 맵 -------------------
                //if (window.kakao && window.kakao.maps) {
                //
                //    this.initDaumGeo();
                //
                //} else {
                //
                //    // global kakao
                //    const script = document.createElement('script');
                //    script.type = 'text/javascript';
                //    script.src = '//dapi.kakao.com/v2/maps/sdk.js?appkey=77d359c6dbb3c6d45d160e8400378411&libraries=services&autoload=false';
                //    script.onload = () => kakao.maps.load(this.initDaumGeo);
                //    document.head.appendChild(script);
                //
                //}
                //---------------- 다음 맵 -------------------
            }

            // 데이터 조회
            , getViewData () {

                var data = {};
                data.gubun_page = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/user/home/view", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1;            // 뷰

                        this.list_1 = data.LIST_1;            // 금관련소식
                        this.list_2 = data.LIST_2;            // 공지사항
                        this.list_3 = data.LIST_3;            // 문의게시판
                        this.list_4 = data.LIST_4;            // 제품사진

                        /*
                        // 데이터 체크
                        if ((this.gubun_page === "VIEW") && this.$IsEmpty(this.view_1.NO)) {

                            alert(INFO_FAIL_ACCESS);
                            window.history.back();

                        } else {

                        }
                        */

                        // dimm 숨기기
                        this.$HideDimmLogo();

                        this.setCntUP();
                    }
                }
            }

            // 다음지도 주소변환
            , initDaumGeo() {

                var _this = this;

                // 주소-좌표 변환 객체를 생성합니다
                var geocoder = new kakao.maps.services.Geocoder();

                // 주소로 좌표를 검색합니다
                geocoder.addressSearch('경기 용인시 수지구 성복2로76번길 26-6', function (result, status) {

                    // 정상적으로 검색이 완료됐으면
                    if (status === kakao.maps.services.Status.OK) {

                        var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
                        _this.initDaumMap(coords);

                    }

                });

            }

            // 다음지도 표시
            , initDaumMap(coords) {

                var mapContainer = document.getElementById('map'), // 지도를 표시할 div
                    mapOption = {
                        center: new kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표
                        level: 4 // 지도의 확대 레벨
                    };

                // 지도를 생성합니다
                var map = new kakao.maps.Map(mapContainer, mapOption);

                var imageSrc = '/common/img/item/logo_marker.png', // 마커이미지의 주소입니다
                    imageSize = new kakao.maps.Size(64, 69), // 마커이미지의 크기입니다
                    imageOption = { offset: new kakao.maps.Point(27, 69) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

                // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
                var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),

                    // 마커를 생성합니다
                    marker = new kakao.maps.Marker({
                        position: coords,
                        image: markerImage // 마커이미지 설정
                    });

                // 마커가 지도 위에 표시되도록 설정합니다
                marker.setMap(map);

                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                map.setCenter(coords);

            }

            // 메인 이미지 loop
            , loopMainItem() {

                var _this = this;

                // 메인 이미지 넣기
                this.list_main_item = [];
                this.list_main_item.push("/common/img/prod_buy_item_1.jpg");
                this.list_main_item.push("/common/img/prod_buy_item_2.jpg");
                this.list_main_item.push("/common/img/prod_buy_item_3.jpg");
                this.list_main_item.push("/common/img/prod_buy_item_4.jpg");
                this.list_main_item.push("/common/img/prod_buy_item_5.jpg");
                this.list_main_item.push("/common/img/prod_buy_item_6.jpg");
                this.list_main_item.push("/common/img/prod_buy_item_7.jpg");

                clearInterval(index_main_item_loop);
                index_main_item_loop = setInterval(function () {

                    var src = _this.list_main_item.shift();
                    _this.list_main_item.push(src);

                    $(".dev_index_item_main").fadeOut(200, function () {
                        $(".dev_index_item_main").css('background-image', 'url(' + src + ')');
                        $(".dev_index_item_main").fadeIn(200, function () { });
                    });

                }, 5000);

            }

            // 숫자
            , setCntUP () {

                var options = {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                };

                var arrPrice = [];
                arrPrice.push(new CountUp("price_gold_1", 0, this.view_1.BUY_GOLD_24_NOW, 0, 2.0, options));
                arrPrice.push(new CountUp("price_gold_2", 0, this.view_1.SELL_GOLD_24_NOW, 0, 2.0, options));
                /*arrPrice.push(new CountUp("price_gold_3", 0, this.view_1.BUY_GOLD_18_NOW, 0, 2.0, options));*/
                arrPrice.push(new CountUp("price_gold_4", 0, this.view_1.SELL_GOLD_18_NOW, 0, 2.0, options));
                /*arrPrice.push(new CountUp("price_gold_5", 0, this.view_1.BUY_GOLD_14_NOW, 0, 2.0, options));*/
                arrPrice.push(new CountUp("price_gold_6", 0, this.view_1.SELL_GOLD_14_NOW, 0, 2.0, options));
                arrPrice.push(new CountUp("price_gold_7", 0, this.view_1.BUY_GOLD_WHITE_NOW, 0, 2.0, options));
                arrPrice.push(new CountUp("price_gold_8", 0, this.view_1.SELL_GOLD_WHITE_NOW, 0, 2.0, options));
                arrPrice.push(new CountUp("price_gold_9", 0, this.view_1.BUY_GOLD_SILVER_NOW, 0, 2.0, options));
                arrPrice.push(new CountUp("price_gold_10", 0, this.view_1.SELL_GOLD_SILVER_NOW, 0, 2.0, options));

                for (var idx = 0; idx < arrPrice.length; idx++) {
                    arrPrice[idx].start();
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);

            // 사진 가로 회전
            $('.owl-carousel').owlCarousel({
                center: false,
                items: 1,
                stagePadding: 3,
                margin: 7,
                smartSpeed: 2000,
                autoplay: false,
                loop: false,
                nav: false,
                dots: true,
                responsive: {
                    0: {
                        items: 4
                    },
                    600: {
                        items: 4
                    },
                    1000: {
                        items: 4
                    }
                }
            });
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>